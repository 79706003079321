export default {
    data() {
        return {
            user: {},
        };
    },
    methods: {
        login() {
            this.$store.dispatch("login/login",this.user);
        },
    },
};